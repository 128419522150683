import {list} from '../../../../domain/processos/services/tribunal'
import window from './window/windowTribunal'

export default {
  pageTitle: 'Tribunais',
  columns: [
    {
      name: 'id',
      required: true,
      label: 'ID',
      align: 'left',
      field: 'id',
      sortable: true,
      parser: function (props) {
        return props.id
      }
    },
    {
      name: 'nome',
      required: true,
      label: 'Vara',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'uf',
      required: true,
      label: 'Estado',
      align: 'left',
      field: 'uf',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: list,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this)
    }
  }
}
